@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Urbanist&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Serif&display=swap);
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.posabsolute {
  position: absolute;
}

.disinlineblock {
  display: inline-block;
}

.div1 {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.div2 {
  border-radius: 0.75rem;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.div3 {
  height: 50%;
  width: 55%;
  border-radius: 0.75rem;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bg {
  background-image: linear-gradient(
    0deg,
    #412e77 0%,
    #412e77 13%,
    #d1c395 90%,
    #a88144 100%
  );
}

.bgcard {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.bgmodal {
  background-color: #f2e7d5;
}

img {
  width: 80px;
  height: 80px;
  margin-bottom: 45px;
}

.labs {
  font-size: 80px;
  color: #2d2d2d;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
  text-shadow: 0px 3px 0px #b2a98f, 0px 14px 10px rgba(0, 0, 0, 0.15),
    0px 24px 2px rgba(0, 0, 0, 0.1), 0px 34px 30px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 80px;
  color: #2d2d2d;
  font-weight: bold;
  margin-bottom: 50px;
  font-family: "Dosis", sans-serif;
}

input {
  width: 35%;
  height: 9%;
  text-align: center;
  background: transparent;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  margin-bottom: 30px !important;
  border-radius: 0.75rem;
  border-color: transparent;
  transition: all 0.5s;
  font-weight: bold;
}

p {
  font-family: "Dosis", sans-serif;
}

input::-webkit-input-placeholder {
  color: #2d2d2d !important;
  font-family: "Encode Sans Expanded", sans-serif;
}

input::placeholder {
  color: #2d2d2d !important;
  font-family: "Encode Sans Expanded", sans-serif;
}

input:hover {
  border: 1.5px solid #2d2d2d !important;
}

input:focus {
  border: 0.5px solid #2d2d2d !important;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.4);
}

:focus-visible {
  outline: 0px;
}

.slide-in-blurred-bottom {
  -webkit-animation: slide-in-blurred-bottom 0.9s cubic-bezier(0.23, 1, 0.32, 1) 1s both;
          animation: slide-in-blurred-bottom 0.9s cubic-bezier(0.23, 1, 0.32, 1) 1s both;
}

@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(0px) scaleY(2.5) scaleX(0.2);
            transform: translateY(0px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    -webkit-transform: translateY(-390px) scaleY(1) scaleX(1);
            transform: translateY(-390px) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(0px) scaleY(2.5) scaleX(0.2);
            transform: translateY(0px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    -webkit-transform: translateY(-390px) scaleY(1) scaleX(1);
            transform: translateY(-390px) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

.flicker-in-1 {
  -webkit-transform: translateY(-390px);
          transform: translateY(-390px);
  -webkit-animation: flicker-in-1 2s linear 1.5s both;
          animation: flicker-in-1 2s linear 1.5s both;
}

@-webkit-keyframes flicker-in-1 {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  10% {
    opacity: 0;
    visibility: hidden;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
    visibility: hidden;
  }
  20% {
    opacity: 0;
    visibility: hidden;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flicker-in-1 {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  10% {
    opacity: 0;
    visibility: hidden;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
    visibility: hidden;
  }
  20% {
    opacity: 0;
    visibility: hidden;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.buttonrun {
  width: 65px;
  height: 45px;
  text-align: center;
  background: transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 0.75rem;
  border-color: transparent;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
  transition: all 0.5s;
}

.buttonrun:hover {
  border: 1.8px solid #2d2d2d;
}

:disabled {
  opacity: 0.5;
}

:disabled:hover {
  cursor: no-drop;
}

.fullmodal {
  width: 680px;
  border-radius: 0.75rem;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 1);
}

.mymodaltitle {
  padding: 1rem;
  margin: 0;
  display: flex;
  width: 100%;
}

.modaltitlecontent {
  font-size: 37px;
  font-weight: bold;
  font-family: "PT Serif", serif;
}

.content {
  padding: 1rem;
}

.svgclose {
  position: absolute;
  top: 10px;
  right: 10px;
}

.svgclose:hover {
  cursor: pointer;
  color: red;
}

.svghelp {
  position: fixed;
  top: 49.1%;
  right: 29%;
}

.svghelp:hover {
  cursor: help;
}

p {
  font-size: 30px;
}

.modalbutton {
  padding: 1rem;
  margin: 0;
  display: flex;
  justify-content: center;
}

.buttonmodal {
  width: 200px;
  background-color: transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 9px;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 1rem;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: bold;
}

.buttonmodal:hover {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 1);
}

.bounce-in-top {
  -webkit-animation: bounce-in-top 1.1s both;
          animation: bounce-in-top 1.1s both;
}

@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    visibility: hidden;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    visibility: hidden;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.slide-out-top {
  -webkit-animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
          animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}

.contactus {
  position: fixed;
  bottom: 5px;
  left: 20px;
  font-size: 25px;
  font-family: "Dosis", sans-serif;
  font-weight: bolder;
  color: black;
  display: flex;
  grid-column-gap: 7px;
  -webkit-column-gap: 7px;
          column-gap: 7px;
}

a {
  text-decoration: none;
  color: black;
}

.loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 20px auto;
  position: relative;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-animation: animloader 2s linear infinite;
          animation: animloader 2s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  width: 6px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  position: absolute;
  bottom: -20px;
  left: 46px;
}

@-webkit-keyframes animloader {
  0% {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
  25% {
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px);
  }
  50% {
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
  }
  75% {
    -webkit-transform: translate(10px, -10px);
            transform: translate(10px, -10px);
  }
  100% {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
}

@keyframes animloader {
  0% {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
  25% {
    -webkit-transform: translate(-10px, 10px);
            transform: translate(-10px, 10px);
  }
  50% {
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
  }
  75% {
    -webkit-transform: translate(10px, -10px);
            transform: translate(10px, -10px);
  }
  100% {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
}

.donation {
  font-family: "Dosis", sans-serif;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 20px;
  color: black;
  position: absolute;
  bottom: 40px;
  font-weight: bolder;
  width: 480px;
  height: 130px;
  padding: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 0.75rem;
  transition: height 0s, width 1s;
}

.donationbutton {
  width: 150px;
  height: 80px;
  position: absolute;
  bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
  transition: all 0.5s;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 25px;
  color: black;
}

.donationbutton:hover {
  cursor: pointer;
}

.vibrate-1 {
  -webkit-animation: vibrate-1 0.3s linear 7s 10 both;
          animation: vibrate-1 0.3s linear 7s 10 both;
}

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

.textapp {
  -webkit-animation: textapp 1s ease-in 1s both;
          animation: textapp 1s ease-in 1s both;
}

@-webkit-keyframes textapp {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes textapp {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.textaddr:hover {
  cursor: copy;
}

@media screen and (max-width: 915px) {
  .title {
    font-size: 200% !important;
  }

  .div3 {
    height: 45%;
    width: 95% !important;
    border-radius: 0.75rem;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  input {
    width: 60% !important;
    height: 8%;
    text-align: center;
    background: transparent;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    margin-bottom: 30px !important;
    border-radius: 0.75rem;
    border-color: transparent;
    transition: all 0.5s;
    font-weight: bold;
  }

  .svghelp {
    visibility: hidden;
  }

  @-webkit-keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(0px) scaleY(2.5) scaleX(0.2);
              transform: translateY(0px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      -webkit-transform: translateY(-300px) scaleY(1) scaleX(1);
              transform: translateY(-300px) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(0px) scaleY(2.5) scaleX(0.2);
              transform: translateY(0px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      -webkit-transform: translateY(-300px) scaleY(1) scaleX(1);
              transform: translateY(-300px) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  .flicker-in-1 {
    -webkit-transform: translateY(-300px);
            transform: translateY(-300px);
    -webkit-animation: flicker-in-1 2s linear 1.5s both;
            animation: flicker-in-1 2s linear 1.5s both;
  }

  .donationbutton {
    width: 40%;
    height: 7%;
    position: absolute;
    bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.75rem;
    font-family: "Urbanist", sans-serif;
    font-weight: 600;
    transition: all 0.5s;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 25px;
    color: black;
  }

  .donation {
    font-family: "Dosis", sans-serif;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 15px;
    color: black;
    position: absolute;
    bottom: 90px !important;
    font-weight: bolder;
    width: 100%;
    height: 15%;
    padding: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 0.75rem;
    transition: height 0s, width 1s;
  }

  .contactus {
    position: fixed;
    bottom: 5px;
    left: 10px;
    font-size: 20px;
    font-family: "Dosis", sans-serif;
    font-weight: bolder;
    color: black;
    display: flex;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .center {
    right: 50%;
  }

  .fullmodal {
    width: -webkit-min-content;
    width: min-content;
    height: -webkit-max-content;
    height: max-content;
  }

  .modaltitlecontent {
    font-size: 25px;
  }

  p {
    font-size: 20px;
  }

  .modalbutton {
    padding: 0.75rem;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .content {
    font-size: 5px;
  }
}

@media screen and (max-height: 430px) {
  .div3 {
    height: 80%;
    width: 70%;
    border-radius: 0.75rem;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .donationbutton {
    visibility: hidden;
  }

  .donation {
    visibility: hidden;
  }

  @-webkit-keyframes textapp {
    0% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @keyframes textapp {
    0% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  .buttonrun {
    width: 100%;
    height: 100%;
  }

  input {
    width: 50%;
    height: 10%;
  }

  .fullmodal {
    width: 650px;
    height: 275px;
  }

  .mymodaltitle {
    padding: 1rem;
    margin: 0;
    display: flex;
    width: 100%;
  }

  .modaltitlecontent {
    font-size: 30px;
    font-weight: bold;
    font-family: "PT Serif", serif;
  }

  p {
    font-size: 25px;
  }

  .modalbutton {
    padding: 1rem;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .buttonmodal {
    background-color: #f2e7d5;
    width: 200px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    padding: 9px;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 1rem;
    font-family: "Encode Sans Expanded", sans-serif;
    font-weight: bold;
  }

  @-webkit-keyframes bounce-in-top {
    0% {
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
      visibility: hidden;
    }
    38% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(-85px);
              transform: translateY(-85px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(-48px);
              transform: translateY(-48px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(-28px);
              transform: translateY(-28px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }

  @keyframes bounce-in-top {
    0% {
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
      visibility: hidden;
    }
    38% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(-85px);
              transform: translateY(-85px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(-48px);
              transform: translateY(-48px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(-28px);
              transform: translateY(-28px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }

  .slide-in-blurred-bottom {
    visibility: hidden;
  }

  .flicker-in-1 {
    visibility: hidden;
  }

  .contactus {
    font-size: 14px;
  }
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: 50px;
  }

  .div3 {
    width: 70%;
  }

  @-webkit-keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(0px) scaleY(2.5) scaleX(0.2);
              transform: translateY(0px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      -webkit-transform: translateY(-330px) scaleY(1) scaleX(1);
              transform: translateY(-330px) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(0px) scaleY(2.5) scaleX(0.2);
              transform: translateY(0px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      -webkit-transform: translateY(-330px) scaleY(1) scaleX(1);
              transform: translateY(-330px) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  .flicker-in-1 {
    -webkit-transform: translateY(-330px);
            transform: translateY(-330px);
    -webkit-animation: flicker-in-1 2s linear 1.5s both;
            animation: flicker-in-1 2s linear 1.5s both;
  }

  input {
    width: 50%;
  }

  .donation {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 2400px) {
  .title {
    font-size: 100px;
  }

  .buttonrun {
    margin-top: 50px;
    width: 80px;
    height: 50px;
  }

  .donationbutton {
    bottom: 150px;
  }

  .donation {
    bottom: 150px;
  }

  @-webkit-keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(0px) scaleY(2.5) scaleX(0.2);
              transform: translateY(0px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      -webkit-transform: translateY(-600px) scaleY(1) scaleX(1);
              transform: translateY(-600px) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(0px) scaleY(2.5) scaleX(0.2);
              transform: translateY(0px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
      visibility: hidden;
    }
    100% {
      -webkit-transform: translateY(-600px) scaleY(1) scaleX(1);
              transform: translateY(-600px) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  .flicker-in-1 {
    -webkit-transform: translateY(-600px);
            transform: translateY(-600px);
    -webkit-animation: flicker-in-1 2s linear 1.5s both;
            animation: flicker-in-1 2s linear 1.5s both;
  }

  .svghelp {
    right: 30%;
    top: 45%;
  }
}

